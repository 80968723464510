import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import {
  Container,
  HeadingContainer,
  LogoContainer,
} from "styles/maintenance.style";
import { PageContainer } from "styles/global.style";
import Heading from "components/core/Typography/Heading";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import { useTranslation } from "react-i18next";
import StaticSvg from "components/core/StaticSvg";

export interface MaintenancePageProps {
  location: Location;
}

export default ({ location }: MaintenancePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper location={location}>
      <SEO
        title={"Maintenance"}
        description={"Powering up your Customerly experience"}
      />
      <Container>
        <PageContainer>
          <LogoContainer>
            <StaticSvg alt={"Customerly"} src={"logo/wide-blue.svg"} />
          </LogoContainer>
          <HeadingContainer>
            <Heading level={1}>{t("pages.maintenance.title")}</Heading>
            <p>{t("pages.maintenance.subtitle")}</p>
            <SafeLink to={"/"}>
              {t("pages.maintenance.goTo")}{" "}
              <Icon type={"chevron-right"} size={16} fill={"#fff"} />
            </SafeLink>
          </HeadingContainer>
        </PageContainer>
      </Container>
    </GlobalWrapper>
  );
};
